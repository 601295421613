<template>
  <div class="container-fluid pb-3" style="margin-top:15px">
  <vue-progress-bar></vue-progress-bar>
    <div class="row">
      <div class="col-lg-12">
        <template v-if="knowledgeBaseItem"> <!-- && this.granted_knowledge_base == 1 -->
          <div class="col">
            <div class="blog-header">
              <h1 class="mt-2">{{ knowledgeBaseItem.title }}</h1>
              <hr>
              <div class="text-muted d-flex justify-content-between">
                <div class="align-self-center">
                  <small class="mr-3"><i class="fe fe-calendar mr-1"></i>{{ knowledgeBaseItem.createdAt.substr(0, 10) }}</small>
                  <small class="mr-3"><i class="fe fe-layers mr-1"></i>{{ knowledgeBaseItem.category.title }}</small>
                  <template v-if="knowledgeBaseItem.tags">
                    <small class="mr-3"><i class="fe fe-tag mr-1"></i>
                      <template v-for="tag in knowledgeBaseItem.tags.split(',')">
                        {{ tag }}
                      </template>
                    </small>
                  </template>
                </div>
                <router-link class="btn btn-primary btn-sm" to="/knowledge_base"><i class="fe fe-chevron-left mr-2"></i>Powrót</router-link>
              </div>
              <hr>
            </div>
            <div v-if="knowledgeBaseItem.photo">
              <img :src='`${dataBaseUrl}/media/knowledge_base/${knowledgeBaseItem.photo}`' alt="photo" class="img-fluid mb-3" />
            </div>
            <div class="blog-content" v-html="knowledgeBaseItem.content"></div>
            <div class="blog-footer">
              <hr>
              <h4>Czy wszystkie opisane rozwiązania zostały przetestowane?</h4>
              <p>Jeśli pomimo wykonania powyższych kroków nadal potrzebujesz pomocy, wyślij zgłoszenie do pomocy technicznej.</p>
              <p class="p-0 py-3">
                <router-link class="basic-info__add-btn btn-sm" to="/tickets/add">Wyślij zgłoszenie</router-link>
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="hasError" class="alert alert-warning alert--grouped mt-3">
            <i class="fe fe-alert-triangle mr-2"></i>
            Uwaga!<br/>Zasób nie istnieje lub nie masz do niego dostępu.
          </div>
          <div v-else class="loader"></div>
        </template>
      </div>
    </div>
  </div><!-- /container-fluid -->
</template>

<script>
// import jwtDecode from 'jwt-decode';
import { baseUrl } from '@/axios-config';
import { mapState } from 'vuex';
export default {
  data: function() {
    return {
      // granted_knowledge_base: 0,
      hasError: false,
      dataBaseUrl: baseUrl,
    };
  },
  created: function() {
    // const token = localStorage.getItem('token');
    // const decoded = token !== null ? jwtDecode(token) : null;

    // if (decoded !== null) {
    //   if (decoded.roles.includes('ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW')) {
    //     this.granted_knowledge_base = 1;
    //   } else {
    //     this.granted_knowledge_base = 0;
    //   }
    // } else {
    //   this.$router.push('login');
    // }
    this.getDataOnCreation();
  },
  computed: mapState('knowledgeBase', ['knowledgeBaseItem']),
  methods: {
    getDataOnCreation: function() {
      this.$Progress.start();
      this.$store.dispatch('knowledgeBase/getKnowledgeBaseContents')
      .then(()=> this.$Progress.finish())
      .catch(error=>{
        this.$Progress.fail();
        this.hasError = true;
        console.error('Error:', error);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
